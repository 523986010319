import { Slider } from "@mui/material";

type SeekType = {
    handleChange: any
    currentTime: number
    duration: number
}

function Seek({
    handleChange,
    currentTime,
    duration,
}: SeekType) {

    return (
        <Slider
            size="small"
            onChange={handleChange}
            value={duration == 0 ? 0 : ((currentTime / duration) * 100)}
            aria-label="Small"
            valueLabelDisplay="auto"
            sx={{
                height: '4px',
                '& .MuiSlider-thumb': {
                    display: 'none'
                }
            }}
        />
    );
}

export default Seek