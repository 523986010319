import {
    Tooltip as MuiTooltip
} from '@mui/material';

type TooltipType = {
    children: any
    message: string | undefined,
    sx?: any,
}

function Tooltip({
    children,
    message,
    sx,
}: TooltipType) {

    return (
        <MuiTooltip
            title={message}
            arrow
            placement='top'
            style={{
                backgroundColor:
                    'lightblue !important',
                ...sx,
            }}
        >
            {children}
        </MuiTooltip>
    )
}

export default Tooltip