import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Seek from '~components/seek';
import { formatTime } from '~helper/index';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayingSongId } from '../store/sharedSlice';

const AudioPlayer = forwardRef(({
    _id = null,
    sx = {},
    audioUrl = "",
    onlySeek = false,
    disableBtn = false,
    togglePlay = false,
    preload: preloadOption = "none",
    setTogglePlay = null,
    timeStyle = {},
    buttonStyle = {},
    buttonStyleIsPlaying = {},
}: any, ref) => {
    const audioRef = useRef<HTMLAudioElement | any>(null);
    const dispatch = useDispatch();
    const { playingSongId } = useSelector((state: any) => state.shared);
    const [isPlaying, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [preload, setPreload] = useState(preloadOption);

    useEffect(() => {
        setDuration(0)
    }, [audioUrl])

    const handlePlayPause = () => {
        if (disableBtn) return;

        if (isPlaying) {
            audioRef?.current?.pause?.();
        } else {
            handlePlay();
        }

        setPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        updateDuration();
        setCurrentTime(audioRef?.current?.currentTime || 0);
    };

    const handleLoadedMetadata = () => {
        updateDuration();
    };

    const handleProgress = function () {
        updateDuration();
        // if (duration === 0 || !isFinite(duration)) {
        //     updateDuration();
        // }
    };

    const updateDuration = () => {
        let audioDuration = audioRef?.current?.duration || 0;
        // if (!isFinite(audioDuration)) {
        //     // If duration is still infinity, use a fallback mechanism
        //     audioDuration = calculateFallbackDuration(audioRef?.current);
        // }
        if (!isFinite(duration) || isFinite(audioDuration) && audioDuration > duration) {
            setDuration(audioDuration);
        }
    };

    const calculateFallbackDuration = (audioElement: any) => {
        // Fallback logic to manually calculate duration if all else fails
        let fallbackDuration1 = 0;
        if (audioElement.buffered.length > 0) {
            fallbackDuration1 = audioElement.buffered.end(audioElement.buffered.length - 1);
            if (!isFinite(fallbackDuration1)) {
                fallbackDuration1 = 0;
            }
        }
        let fallbackDuration2 = 0;
        if (audioElement.played.length > 0) {
            fallbackDuration2 = audioElement.played.end(audioElement.played.length - 1);
            if (!isFinite(fallbackDuration2)) {
                fallbackDuration2 = 0;
            }
        }
        let fallbackDuration12 = fallbackDuration1 > fallbackDuration2 ? fallbackDuration1 : fallbackDuration2;

        let fallbackDuration3 = 0;
        if (audioElement.seekable.length > 0) {
            fallbackDuration3 = audioElement.seekable.end(audioElement.seekable.length - 1);
            if (!isFinite(fallbackDuration3)) {
                fallbackDuration3 = 0;
            }
        }

        let fallbackDuration123 = fallbackDuration12 > fallbackDuration3 ? fallbackDuration12 : fallbackDuration3;

        return fallbackDuration123;
    };
    var timer: any = null;
    const seekToTime = async (video_element: any, ts: number) => {
        if (timer) return;
        // try and avoid pauses after seeking
        // video_element.pause();
        video_element.currentTime = ts; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
        // however if it close enough, then we need to call play manually
        // some shenanigans to try and work around this:

        timer = setTimeout(async () => {
            //    if (video_element.paused && video_element.readyState == 4 || !video_element.paused) {
            //video_element.play();
            //clearInterval(timer);
            if (video_element.currentTime < ts) {
                await video_element.load();
                await video_element.play();
                video_element.currentTime = ts; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
            }
            timer = null;
            //  }
        }, 500);
    }

    const handleChange = (_: any, value: any) => {
        if (disableBtn) return;

        if (audioUrl.includes('watermark'))
            return;
        if (duration == 0)
            return;

        const currentTime = duration * (value / 100);
        if (isFinite(currentTime)) {
            seekToTime(audioRef?.current, currentTime)
            //audioRef?.current.currentTime = currentTime;
        }
    };

    const handleError = function () {
        const err = audioRef?.current?.error;
        console.log(err, err?.stack)
    }

    const handlePlay = async () => {
        try {
            await audioRef?.current?.play?.();
            setPlaying(true);
            dispatch(setPlayingSongId(_id));
            // if (preload == 'none') {
            //     setPreload('auto');
            // }
        } catch (e) {
            if (!e?.message?.includes("user didn't interact with the document first")) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (togglePlay) {
            handlePlay();
        } else {
            audioRef?.current?.pause?.();
            if (audioRef?.current?.currentTime)
                audioRef.current.currentTime = 0;
        }
    }, [togglePlay]);

    useEffect(() => {
        if (playingSongId !== _id && playingSongId != null) {
            audioRef?.current?.pause?.();
            if (audioRef?.current?.currentTime)
                audioRef.current.currentTime = 0;
            setPlaying(false);
            setTogglePlay?.(false);
        }
    }, [playingSongId]);

    useImperativeHandle(ref, () => ({
        play: handlePlay,
        pause: async () => {
            await audioRef?.current?.pause?.();
            setPlaying(false);
        },
        getCurrentTime: () => audioRef?.current?.currentTime || 0
    }));

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                border: '0px solid red',
                ...sx,
            }}
        >
            {!onlySeek && (
                <button
                    style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: disableBtn ? 'default' : 'pointer',
                        backgroundColor: isPlaying ? '#EF7484' : '#849EB2',
                        color: 'white',
                        ...buttonStyle,
                        ...(isPlaying ? buttonStyleIsPlaying : {}),
                    }}
                    onClick={handlePlayPause}
                >
                    {isPlaying ? (
                        <PauseIcon
                            style={{
                                fontSize: '40px',
                                width: '50px',
                            }}
                        />
                    ) : (
                        <PlayArrowIcon
                            style={{
                                fontSize: '40px',
                                width: '50px',
                            }}
                        />
                    )}
                </button>
            )}
            <Seek handleChange={handleChange} currentTime={currentTime} duration={duration} />
            {!onlySeek && (
                <div
                    style={{
                        border: '0px solid red1',
                        width: '55px',
                        ...timeStyle,
                    }}
                >
                    {formatTime(currentTime)}
                </div>
            )}
            <audio
                preload={preload}
                ref={audioRef}
                src={audioUrl}
                onError={handleError}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onProgress={handleProgress}
            />
        </div>
    );
});

export default AudioPlayer;