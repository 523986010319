import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Box } from '@mui/material'
import LoginModals from '../../shared/modal-dialogs/login-modals';

const Header: any = dynamic(() => import('./Header'), { ssr: false })
const Footer: any = dynamic(() => import('./Footer'), { ssr: false })

type Props = {
  children?: React.ReactNode
}

export default function MainLayout({ children }: Props) {


  const isHome = false

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 1,
        }}
      >
        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ...(!isHome && {
              pt: { xs: 8, md: 11 },
            }),
            border: '0px solid red'
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
      <LoginModals />
    </>
  )
}
